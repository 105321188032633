import React from "react";
import OnboardingLastStep from "../onboardingLastStep";

function CustomizationPreview(props) {
    const { location } = props
    const backLink = location && location.state && location.state.backLink
    const extensionName =
        location && location.state && location.state.extensionName
    const from = location && location.state && location.state.from
    const rules = location && location.state && location.state.rules
    const id = location && location.state && location.state.id

    return(
        <>
        <OnboardingLastStep backLink={backLink} extensionName={extensionName} from={from} rules={rules} id={id}/>
        </>
    )
  }
  
  export default CustomizationPreview;